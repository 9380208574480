import { BrowserRouter, Navigate, Routes, Route } from 'react-router-dom';

import Construction from './construction';

import '../css/app.css';

const App = () => {
  return (
    <div className='App'>
      <BrowserRouter>
        <Routes>
          <Route path='/construction' element={<Construction />} />
          <Route path='*' element={<Navigate replace to='/construction' />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
};

export default App;

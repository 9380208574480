import pixelHead from '../img/bdd-head-sprite.png';
import bddLogo from '../img/logos/logo_trans_celadon.png';

import '../css/construction.css';

const Construction = () => {
    return (
        <div className='Construction'>
            <img src={bddLogo} />
            <img src={pixelHead} />
            <h1>This site is currently under construction.</h1>
            <h4>Awesomeness takes time. Please come back later.</h4>
        </div>
    );
};

export default Construction;